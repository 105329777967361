.form {
    padding: 1rem;
  }
  
  .control {
    margin-bottom: 0.5rem;
  }
  
  .control label {
    display: block;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  
  .control input,
  .control textarea {
    display: block;
    font: inherit;
    border-radius: 4px;
    border: 1px solid #ccc;
    padding: 0.25rem;
    width: 100%;
  }
  .checkbox {
    display: block;
    font: inherit;
    border-radius: 4px;
    padding: 0.25rem;
    width: auto;
  }
  .checkbox label {
    display: block;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  
  .actions {
    margin-top: 1rem;
    text-align: right;
  }
  
  .actions button {
    font: inherit;
    cursor: pointer;
    background-color: #77002e;
    color: white;
    padding: 0.5rem 1.5rem;
    border: 1px solid #77002e;
    border-radius: 4px;
    font-weight: bold;
  }
  
  .actions button:hover,
  .actions button:active {
    background-color: #a50e48;
    border-color: #a50e48;
  }
  .datePicker label {
    display: block;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }