* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Fira Sans", sans-serif;
}

body {
    background-color: #DDD;
}

.App {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
}

.c-player {
    display: block;
    background-color: #313131;
    display: block;
    margin: 0px auto;
    padding: 50px;
    border-radius: 16px;
    box-shadow: inset -6px -6px 12px rgba(0, 0, 0, 0.8), inset 6px 6px 12px rgba(255, 255, 255, 0.4);
}

.c-player > h4 {
    color: #FFF;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500;
    text-align: center;
}

.c-player > p {
    color: #AAA;
    font-size: 14px;
    text-align: center;
    font-weight: 600;
}

.c-player > p span {
    font-weight: 400;
}

.c-player--details .details-img {
    position: relative;
    width: fit-content;
    margin: 0 auto;
}

.c-player--details .details-img img {
    display: block;
    margin: 50px auto;
    width: 100%;
    max-width: 250px;
    border-radius: 50%;
    box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.8), -6px -6px 12px rgba(255, 255, 255, 0.4);
}

.c-player--details .details-img:after {
    content: '';
    display: block;
    position: absolute;
    top: -25px;
    left: -25px;
    right: -25px;
    bottom: -25px;
    border-radius: 50%;
    border: 3px dashed rgba(255, 206, 0, 1);
}

.c-player--details .details-title {
    color: #EEE;
    font-size: 28px;
    text-shadow: 2px 2px 4px rgba(0,0,0,0.8), -2px -2px 4px rgba(255,255,255,0.4);
    text-align: center;
    margin-bottom: 10px;
}

.c-player--details .details-artist {
    color: #AAA;
    font-size: 20px;
    text-shadow: 2px 2px 4px rgba(0,0,0,0.8), -2px -2px 4px rgba(255,255,255,0.4);
    text-align: center;
    margin-bottom: 20px;
}

.c-player--controls {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
}

.c-player--controls .play-btn {
    display: flex;
    margin: 0 30px;
    padding: 20px;
    border-radius: 50%;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.8), -4px -4px 10px rgba(255, 255, 255, 0.4), inset -4px -4px 10px rgba(0, 0, 0, 0.4), inset 4px 4px 10px rgba(255, 255, 255, 0.4);
    border: none;
    outline: none;
    background-color: #FFCE00;
    color: #FFF;
    font-size: 24px;
    cursor: pointer;
}

.c-player--controls .skip-btn {
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    color: #888;
    font-size: 18px;
}